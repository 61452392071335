import React, { useState, useEffect } from 'react';

const CountdownTimer = () => {

  const [countdownTime, setCountdownTime] = useState('');
  // Helper function to calculate the countdown time
  const calculateCountdown = () => {
    // Get the current time in PST
    const now = new Date();
    const pstDate = new Date(now.toLocaleString("en-US", { timeZone: "America/Los_Angeles" }));
  
    // Set the target time to the next Friday at 3:30 PM
    const targetTime = new Date(pstDate);
    targetTime.setHours(15, 30, 0, 0); // 3:30 PM
    targetTime.setDate(targetTime.getDate() + (5 - targetTime.getDay() + 7) % 7); // Find next Friday
  
    // If it's currently Friday but past 3:30 PM, set the target to the next Friday
    if (pstDate.getDay() === 5 && pstDate > targetTime) {
      targetTime.setDate(targetTime.getDate() + 7);
    }
  
    // Calculate the difference in milliseconds
    const timeDifference = targetTime - pstDate;
  
    // Convert the difference to days, hours, minutes, and seconds
    let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    let hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
    let minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
    let seconds = Math.floor((timeDifference / 1000) % 60);
  
    // Pad the numbers with leading zeros if needed
    // days = (days < 10) ? "0" + days : days;
    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    
    // Update days formatting to remove leading zero
    days = days.toString(); // No need for leading zero padding for days

  
    // Return the formatted countdown string including days
    return `${days} Days ${hours} Hours ${minutes} Mins ${seconds} Secs`;
  }
  useEffect(() => {
    const updateCountdown = () => {
      setCountdownTime(calculateCountdown());
    };

    updateCountdown(); // Run it immediately on mount
    const intervalId = setInterval(updateCountdown, 1000); // Then every second

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="countdown">
      <p>New Stock Launch in:</p>
      {countdownTime}
      <p>Trusted by over 5,000 investors, brokers, and fund managers.</p>
    </div>
  );
};

export default CountdownTimer;