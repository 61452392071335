import React from 'react';

const ProductList = ({ stocks, onVote, session }) => {
    return (
        <div className="product-list">
        {/* Table Heading 
        <div className="product-heading">
            <div className="heading-icon">Icon</div>
            <div className="heading-name"></div>
            <div className="heading-symbol"></div>
            
            <div className="heading-price"></div>
            <div className="heading-volume"></div>
            
            <div className="heading-sector"></div>
        </div>
        */}
      {/* Product Rows */}
        {stocks.map(stock => (
          <div key={stock.id} className="product">
            {stock.company_icon ? (
                <img src={stock.company_icon} alt={stock.company_name} className="company-icon" />
            ) : (
                <div className="icon-placeholder"></div> // Add this line for the placeholder
            )}
            <span className="product-name">{stock.company_name}</span>
            <span className="product-ticker">{stock.ticker_symbol}</span>
            
            <span className="product-eod-price">${stock.price}</span>
            <span className="product-volume">{stock.volume}</span>
            
            <span className="product-description">{stock.sector}</span>
            <button className="upvote-button" onClick={() => onVote(stock.id)}>
                <div className="upvote-arrow"></div>
                <div className="vote-count">{stock.votes}</div>
            </button>
          </div>
        ))}
      </div>
    );
  };  

export default ProductList;
  
// <div className="heading-change">Change</div> <span className="product-change-percent">{stock.change_percent}</span> <div className="heading-cap">Market Cap</div> <span className="product-market-cap">${stock.market_cap}</span> 