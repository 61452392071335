import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { createClient } from '@supabase/supabase-js'

const supabaseUrl = 'https://gqvpcbvwyjntzgiybtvi.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdxdnBjYnZ3eWpudHpnaXlidHZpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDgwMzA3NjgsImV4cCI6MjAyMzYwNjc2OH0.Ix2bnOW5_4OmQnl2uie5sa5vaPHL6zNIhrcQu-bHhkU';
const supabase = createClient(supabaseUrl, supabaseKey)

const getLastDayStocksCan = async () => {
    try {
      let { data: stocksCan, error } = await supabase
        .from('stocks_can')
        .select('*')
        .eq('display', true)
        // If you want to add specific date logic, you can do so here
        .order('votes', { ascending: false }) // Assuming you have a 'created_at' or similar timestamp field for ordering
        .limit(50); // Adjust the limit as needed
  
      if (error) throw error;
      return stocksCan;
    } catch (error) {
      console.error('Error fetching stocks_can:', error);
      return []; // Return an empty array in case of an error
    }
  };

const getThisWeeksStocks = async () => {
    try {
      // Get the current date and calculate the start and end of the week
      const today = new Date();
      const dayOfWeek = today.getDay(); // Sunday - 0, Monday - 1, etc.
      const startOfWeek = new Date(today.setDate(today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6:1))); // Set to Monday
      startOfWeek.setHours(0, 0, 0, 0); // Start of the day
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(endOfWeek.getDate() + 6); // End of the week, Sunday
      endOfWeek.setHours(23, 59, 59, 999); // End of the day
  
      let { data: stocks, error } = await supabase
        .from('stocks')
        .select('*')
        .gte('ipo_date', startOfWeek.toISOString())
        .lte('ipo_date', endOfWeek.toISOString())
  
      if (error) throw error;
  
      // Assuming your 'stocks' table has 'ipo_date' as a Date or DateTime column
      return stocks;
    } catch (error) {
      console.error('Error fetching stocks:', error);
      return []; // Return an empty array in case of an error
    }
  };
  
// export default getThisWeeksStocks;
export default getLastDayStocksCan;