import React from 'react';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { supabase } from '../supabaseClient'; // Make sure this path is correct

const SignInPopup = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="popup-overlay">
      <div className="popup-inner">
        
        <Auth
          supabaseClient={supabase}
          appearance={{ 
            theme: ThemeSupa, 
            style: {
              button: {
                backgroundColor: '#DA552F',
                color: 'white',
                border: '0px solid',
              },
            },
          }}
          providers={['google']} // Add other providers as needed
          onlyThirdPartyProviders={false} // Set to false to include email/password option
        />
        <button className="close-btn" onClick={onClose}>&times;</button>
      </div>
    </div>
  );
};

export default SignInPopup;

// // SignInPopup.js
// import React, { useState } from 'react';
// import { supabase } from '../supabaseClient'; // Make sure this path is correct
// import googleLogo from './signin_withgoogle.svg'; // Update with the correct path

// const SignInPopup = ({ show, onClose }) => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [loading, setLoading] = useState(false);

//   if (!show) {
//     return null;
//   }

//   const handleGoogleSignIn = async () => {
//     setLoading(true);
//     await supabase.auth.signInWithOAuth({
//       provider: 'google',
//     });
//     setLoading(false);
//   };

//   const handleEmailSignIn = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     const { error } = await supabase.auth.signIn({
//       email,
//       password,
//     });
//     if (error) {
//       console.error('Error in email sign-in:', error.message);
//     }
//     setLoading(false);
//   };

//   return (
//     <div className="popup-overlay">
//       <div className="popup-inner">
//         <button className="close-btn" onClick={onClose}>&times;</button>
//         <h3>Sign up or Login to Vote</h3>
//         <button className="google-sign-in-btn" onClick={handleGoogleSignIn}>
//           <div className="Google-button">
//             <img src={googleLogo} alt="Google logo" />
//             Sign in with Google
//           </div>
//         </button>
//         <form onSubmit={handleEmailSignIn} className="email-sign-in-form">
//           <input
//             type="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             placeholder="Email"
//             required
//           />
//           <input
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             placeholder="Password"
//             required
//           />
//           <button type="submit" className="email-sign-in-btn" disabled={loading}>
//             {loading ? 'Loading...' : 'Sign Up / Login'}
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default SignInPopup;
