import logo from './logo.svg';
import axios from 'axios'; // Add this line to import axios
import React, { useState, useEffect } from 'react'; // Import useState and useEffect
import './App.css';
import getLastDayStocksCan from './components/Stocklists'; // Adjust the import path as needed
import ProductList from './components/ProductList';
import StockList from './components/StockList';
import { supabase } from './supabaseClient'; // Adjust the path as needed
import { Auth, ThemeSupa } from '@supabase/auth-ui-react';
import SignInPopup from './components/SignInPopup';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import LoginPage from './LoginPage'; // Your login page component
import CountdownTimer from './components/countdownTimer'; // import the new CountdownTimer component



const App = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [stocks, setStocks] = useState([]); // Define 'stocks' state using useState
  
  // At the start of your App component, add a session state
  const [session, setSession] = useState(null);
  
  useEffect(() => {
    checkSession();

    fetchStocks();

    // subscribing to real time updates from Supabase
    const changes = supabase
    .channel('schema-db-changes') // This name is just an example. Adjust according to your setup.
    .on(
      'postgres_changes', // This is the default event for listening to Postgres changes.
      { event: 'UPDATE', schema: 'public', table: 'stocks_can' }, // Specify your schema and table name
      (payload) => {
        console.log('Change received:', payload);
        // Handle the change. For example, update your component state here.
        setStocks(currentStocks => {
          return currentStocks.map(stock => {
            if (stock.id === payload.new.id) {
              return { ...stock, votes: payload.new.votes };
            }
            return stock;
          });
        });
      }
    )
    .subscribe();
  }, []);

  const checkSession = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    setSession(session);
  };

  const fetchStocks = async () => {
    let data = await getLastDayStocksCan();
    // Sort stocks by votes in descending order
    data.sort((a, b) => b.votes - a.votes);
    setStocks(data);
  };

  // Function to handle voting
  const handleVote = async (id, upvoted) => {
    if (!session) {
    // Instead of showing an alert, initiate login with Google popup
    await setShowPopup(true);
    return;
  }
  const userId = session.user.id; // Retrieve the user ID from the session

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/upvote/${id}`, {userId});

    // Update the state with the new votes count
    setStocks(currentStocks => currentStocks.map(stock => {
      if (stock.id === id) {
        // Use the updated vote count from the response
        return { ...stock, votes: response.data.newVotes };
      }
      return stock;
    }));
    } catch (error) {
      console.error('Error while voting', error);
      // Handle error, maybe show a message to the user
    }
  };

  return (
    <div className="app">
        <div className="navbar">
        <img src={logo} alt="StockHunt Logo" className="navbar-logo" />
        {session ? (
          <button 
            className="button" 
            onClick={async () => {
              await supabase.auth.signOut();
              setSession(null); // Reset session state to null on sign out
            }}>
            Sign Out
          </button>
        ) : (
          <button 
            className="button" 
            
            onClick={() => setShowPopup(true)}>
            Login / Sign Up
          </button>
        )}
      </div> 
          <div className='big-text'>
            Stocks Launching This Week
          </div>
          {!session && ( // Use logical '&&' operator for conditional rendering
            
              <h3>Sign in to vote on your favourite stock!</h3>
            
          )}
          <SignInPopup show={showPopup} onClose={() => setShowPopup(false)} />
          <CountdownTimer />
      <ProductList stocks={stocks} onVote={handleVote} session={session}/>
      <div className="newsletter-signup">
        <p>Get the best of StockHunt, directly in your inbox.</p>
        <form>
          <input type="email" placeholder="you@email.com"/>
          <button type="submit">Sign me up</button>
        </form>
      </div>
    </div>
  );
};

export default App;